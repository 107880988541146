import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18nt/index";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { ScrollToTop } from "./components/Content/ScrollToTop/ScrollToTop";
import AppRouter from "./routes/AppRouter";
import ColorModeProvider from "./context/ColorModeContext";
import { initGA, trackPageView } from "./utils/googleAnalytics";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ColorModeProvider>
        <Header />
        <AppRouter />
        <Footer />
        <ScrollToTop />
      </ColorModeProvider>
    </I18nextProvider>
  </React.StrictMode>
);

initGA();
trackPageView(window.location.pathname);

reportWebVitals();

import {
  HeroContentContainer,
  HeroTitle,
  HeroText,
  HeroImage,
} from "./HeroContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { ButtonLink } from "../../ButtonLink/ButtonLink";
import SrbImage from "../../../assets/images/png/SRB-hero-img.png";
import EngImage from "../../../assets/images/png/ENG-hero-img.png";

export const HeroContent = (props: HeroContentProps) => {
  const { i18n  } = useTranslation();
  const heroImage = i18n.language === 'sr' ? SrbImage : EngImage;

  return (
    <HeroContentContainer>
      <HeroTitle>
        <Trans i18nKey={"sectionHero.title"} />
      </HeroTitle>
      <HeroText>
        <Trans i18nKey={"sectionHero.text"} />
      </HeroText>
      <ButtonLink href="#contact" largeButton={true}>
        {<Trans i18nKey={"common.contactUs"} />}
      </ButtonLink>
      <HeroImage src={heroImage} />
    </HeroContentContainer>
  );
};

export interface HeroContentProps {
  //$backgroundColor: string;
}
export interface HeroImageProps {
  //$backgroundColor: string;
  id?: string;
  children: ReactNode;
  title?: string;
  showSectionDecoration?: boolean;
}

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Link } from "@mui/material";
import { Section } from "../../components/Section/Section";
import { HeroContent } from "../../components/Content/HeroContent/HeroContent";
import SrbContactImage from "../../assets/images/png/SRB-about-us-and-background-img.png";
import EngContactImage from "../../assets/images/png/ENG-about-us-and-background-img.png";
import { ContactContent } from "../../components/Content/ContactContent/ContactContent";
import { CtaContent } from "../../components/Content/CtaContent/CtaContent";
import { WhyContent } from "../../components/Content/WhyContent/WhyContent";
import { InspectMasterPosibillity } from "../../components/Content/InspectMasterPosibillity/InspectMasterPosibillity";
import { AboutUsContent } from "../../components/Content/AboutUsContent/AboutUsContent";
import { FAQContent } from "../../components/Content/FAQContent/FAQContent";
import { TrialContent } from "../../components/Content/TrialContent/TrialContent";

export const HomePage = (props: LoginPageProps) => {
  const { i18n } = useTranslation();
  const Image = i18n.language === 'sr' ? SrbContactImage : EngContactImage;

  return (
    <Box sx={{ marginTop: "80px" }}>
      <Section showSectionDecoration>
        <HeroContent />
      </Section>
      <Section id="benefits" title={"sectionWhy.title"}>
        <WhyContent />
      </Section>
      <Section
        backgroundPosition={"0 0, 0 0, 50% 60%"}
        backgroundImage={Image}
      >
        <CtaContent />
      </Section>
      <Section title={"sectionMakePossible.title"}>
        <InspectMasterPosibillity />
      </Section>
      <Section title={"sectionTrial.title"} showSectionDecoration>
        <TrialContent></TrialContent>
      </Section>
      <Section id="help" title={"sectionFAQ.title"}>
        <FAQContent />
      </Section>
      <Section title={"sectionAbout.title"} showSectionDecoration>
        <AboutUsContent />
      </Section>
      <Section
        id="contact"
        title={"sectionContact.title"}
        backgroundImage={Image}
      >
        <ContactContent />
      </Section>
    </Box>
  );
};

export interface LoginPageProps {}

export interface LoginPageFormInput {
  username: string;
}

import { 
    PosibillityContainer, 
    PosibillityContainerLeft, 
    PosibillityContainerRight,
    PosibillityInnerContainer,
    Posibillity,
    PosibilityImg 
} 
from './InspectMasterPosibillity.styled'
import { useTranslation } from "react-i18next";
import {ReactComponent as PosibillityIcon} from '../../../assets/images/svg/check-icon.svg';
import SrbPosibillityImage from '../../../assets/images/png/SRB-mob-img.png';
import EngPosibillityImage from '../../../assets/images/png/ENG-mob-img.png';
export const InspectMasterPosibillity = () => {
    const { t, i18n } = useTranslation();
    const posibillities = t('sectionMakePossible.items', { returnObjects: true });
    let possibilitiesArray: string[] | null = null;
    if (posibillities && typeof posibillities === 'object') {
         possibilitiesArray = Object.values(posibillities);
    }
    const PosibillityImage = i18n.language === 'sr' ? SrbPosibillityImage : EngPosibillityImage;

    return (
        <PosibillityContainer>
            <PosibillityContainerLeft>
                {
                    possibilitiesArray &&
                    possibilitiesArray?.map((el:string, index:number) => (
                        <PosibillityInnerContainer  key ={index}>
                            <div><PosibillityIcon/></div>
                            <Posibillity>{el}</Posibillity>
                        </PosibillityInnerContainer>
                    ))
                }
            </PosibillityContainerLeft>
            <PosibillityContainerRight>
                <PosibilityImg src={PosibillityImage}/>
            </PosibillityContainerRight>
        </PosibillityContainer>
    );
}
import {
  AboutUsContentContainer,
  AboutUsBr,
  AboutUsImage,
  AboutUsText,
} from "./AboutUsContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import SrbAboutUsImage from "../../../assets/images/png/SRB-about-us-and-background-img.png";
import EngAboutUsImage from "../../../assets/images/png/ENG-about-us-and-background-img.png";

export const AboutUsContent = (props: AboutUsContentProps) => {
  const { i18n } = useTranslation();
  const Image = i18n.language === 'sr' ? SrbAboutUsImage : EngAboutUsImage;

  return (
    <AboutUsContentContainer>
      <AboutUsText>
        <Trans
          i18nKey={"sectionAbout.text"}
          components={{
            1: <AboutUsBr />,
          }}
        />
      </AboutUsText>
      <AboutUsImage src={Image}></AboutUsImage>
    </AboutUsContentContainer>
  );
};

export interface AboutUsContentProps {}

import ReactGA from "react-ga4";

export const initGA = (): void => {
  const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
  if (gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId);
  } else {
    console.error("GA Measurement ID is not defined!");
  }
};

export const trackPageView = (path: string): void => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category: string, action: string, label?: string): void => {
  ReactGA.event({ category, action, label });
};
